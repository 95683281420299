import React from 'react';
import { arrayOf, func, node, oneOf, shape, string } from 'prop-types';

// Block components
import BlockDefault from './BlockDefault';
import BlockFooter from './BlockFooter';
import BlockSocialMediaLink from './BlockSocialMediaLink';
import { useConfiguration } from '../../../context/configurationContext';

///////////////////////////////////////////
// Mapping of block types and components //
///////////////////////////////////////////

const defaultBlockComponents = {
  defaultBlock: { component: BlockDefault },
  footerBlock: { component: BlockFooter },
  socialMediaLink: { component: BlockSocialMediaLink },
};

////////////////////
// Blocks builder //
////////////////////

const BlockBuilder = props => {
  const config = useConfiguration();
  const { blocks, options, ...otherProps } = props;

  // Extract block & field component mappings from props
  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const { intl, blockComponents, fieldComponents, currentUser } = options || {};
  const blockOptionsMaybe = fieldComponents ? { options: { fieldComponents } } : {};
  const { accountType } = (currentUser && currentUser.id && currentUser.attributes.profile.publicData) || {};
  const isCompany = accountType ? accountType == 'company' : true;

  // If there's no block, we can't render the correct block component
  if (!blocks || blocks.length === 0) {
    return null;
  }

  // Selection of Block components
  // Combine component-mapping from props together with the default one:
  const components = { ...defaultBlockComponents, ...blockComponents };
  return (
    otherProps.sectionId == "fractional-professionals"
      ? config.listing.parentJobRoles.map((pjr, i) => {
        const block = JSON.parse(JSON.stringify(blocks[i % 2]));
        block.title["fieldType"] = block.title["fieldType"];
        block.title["content"] = pjr.label;
        block.callToAction["href"] = block.callToAction["href"].split('=')[0] + '=' + pjr.label;
        block.callToAction["content"] = pjr.label;
        block.blockId = "first" + i;
        block.media['alt'] = pjr.label;

        const bConfig = components[block.blockType];
        const Block = bConfig?.component;
        if (Block) {
          return <Block key={(block.blockId + '-' + i)} isCompany={isCompany} currentUser={currentUser} listingConfig={config && config.listing} {...block} {...blockOptionsMaybe} {...otherProps} />;
        } else {
          // If the block type is unknown, the app can't know what to render
          console.warn(`Unknown block type (${block.blockType}) detected.`);
          return null;
        }
      })
      : blocks.map((block, i) => {
        const bConfig = components[block.blockType];
        const Block = bConfig?.component;
        if (Block) {
          return <Block key={(block.blockId + '-' + i)} {...block} intl={intl} currentUser={currentUser} isCompany={isCompany} listingConfig={config && config.listing} {...blockOptionsMaybe} {...otherProps} />;
        } else {
          // If the block type is unknown, the app can't know what to render
          console.warn(`Unknown block type (${block.blockType}) detected.`);
          return null;
        }
      })
  );
};

const propTypeBlock = shape({
  blockId: string.isRequired,
  blockType: oneOf(['defaultBlock', 'footerBlock', 'socialMediaLink']).isRequired,
  // Plus all kind of unknown fields.
  // BlockBuilder doesn't really need to care about those
});

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
  blockComponents: shape({ component: node }),
});

BlockBuilder.defaultProps = {
  blocks: [],
  options: null,
  responsiveImageSizes: null,
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
};

BlockBuilder.propTypes = {
  blocks: arrayOf(propTypeBlock),
  options: propTypeOption,
  responsiveImageSizes: string,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
};

export default BlockBuilder;
